:root{
  --text-color: #272838;
  --primary-color: #7D8491;
  --secondary-color: #5D536B;
  --tertiary-color: #989FCE;
  --other-color: #C0C5C1;
}
@import url('https://fonts.googleapis.com/css2?family=Quattrocento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Quattrocento&display=swap');

body{
  font-family: 'Quattrocento', serif;
  background-color: var(--primary-color);
  overflow-x: hidden;

}
.App{
  justify-content: center;
}

.banner{
  width: 99vw;
  height: 100vh;
  margin-top: -100px;
}

.nav{
  position: absolute;
  display: flex;
  z-index: 200;
  width: 60%;
  justify-content: space-evenly;
  top:40px;
}

.navContainer{
  position:relative;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  padding-bottom: 100px;
}

.nav a{
  text-decoration: none;
  font-weight: 800;
  font-size: 1.2em;
  color: white;
  max-width: 2090px;
}

.nav a:hover{
  color: black;
}

.mobileNav{
  z-index: 200;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobileNav a{
  color: var(--text-color);
  font-size: 20px;
  width: 100%;
  font-weight: 800;
  text-decoration: none;
  margin: 15px 0px;
}

.banner img{
  width: 100vw;
  height: 100vh;
  filter: brightness(0.8);
  z-index: -1;
  margin-bottom: -5px;
}

.bannerTitle{
  font-family: 'Dancing Script', cursive;  font-weight: 600;
  font-size: 55px;
  color: white;
  position: absolute;
  left: 2%;
  top: 10px;
  z-index: 1;
  margin: 0;
  padding: 0px;
}
.bannerTitle:hover{
  cursor: default;
}
.logoContainer{
  position: absolute;
  width:100%;
}

.bannerButton{
  position: absolute;
  z-index: 100;
  border: 3px solid white;
  color: white;
  font-size: 30px;
  font-weight: 600;
  padding: 15px;
  text-decoration: none;
  right:15%;
  bottom: 25%;

}
.bannerButton:hover {
  color: black;
  border: 3px solid black;
  transition: 0.2s;
}

.logo{
  position: absolute;
  left: 0px;
  top: 0px;
}

/* page margins */



/* collections page */

.collectionContainer {
  display: flex;
  flex-wrap: wrap;
  width:98%;
}
.innerCollectionItem{
  background-color: var(--other-color);
}
.collectionItem{
  min-width: 360px;
  flex:1;
  margin: 5px;

}
.collectionItem:hover{
  opacity: 70%;
  cursor: pointer;
}

.collectionImage{
  height: 40vh;
  object-fit: cover;
  width: 100%;
}

.collectionItemTitle{
  font-size: 20px;
  font-weight: 900;
  text-decoration: none !important;
  color: var(--text-color);
  margin: 0;
  padding: 5px 15px;
}


/* Painting */

.painting{
  min-width: 300px;
  max-width: 99%;
  margin: 0px;
  padding: 0px;
}

.paintingsInnerContainer{
  width:90%;
  height:fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.paintingsOuterContainer{
    display: flex;
    justify-content: center;
    width: fit-content;
    overflow: hidden;
}
.paintingItemContainer{
  display: flex;
  position: relative;

}
.paintingItemContainer:hover{
  cursor: pointer;
  opacity: 70%;
}


/* individual artwork */

.artwork{
  width: 100vw;
  height: 85vh;
  display: grid;
  justify-content: center;
  align-items: center;
}
.arrowIcon{
  width:20px;
  height:25px;
  position:absolute;
  bottom: 15px;
  right: 15px;
}
.artworkContentContainer{
  display: flex;
  flex-wrap: wrap;
  object-fit: scale-down !important;
  color:var(--text-color);
}

.artworkImageContainer{
  justify-content: center;
  text-align: center;
  margin: auto;
}

.artworkImageContainer img{
  max-height: 85vh;
  object-fit: scale-down;
}

.paintingDataContainer{
  margin: 0px 25px;
  min-width: 140px !important;
}



/* services */

.services{
  padding: 0px 40px;
  color: var(--text-color);
}
.services a{
  color:var(--text-color);
}

.servicesIntoText{
  font-size: larger;
}

/* contact */

.contactTitle {
  font-size: 60px;
  color: var(--text-color);
  padding: 0;
  margin: 0;
  margin-top: 30px;
}

.contact{
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact img{
  width:50px;
  margin: 0px 20px;
}
.contact .contactIconContainer .icon:hover{
  opacity: 70%;
  transform: scale(1.1);
}

.contactText{
  font-size: 20px;
  padding: 0px;
}

.contactText a{
  color:var(--text-color);
}

.contactIconContainer{
  padding: 10px;
}

.contactSpan{
  font-size: 25px;
}



/* vision section */

.vision{
  padding: 0px 40px;
  color: var(--text-color);
}
.vision p{
  font-weight: 500;
  line-height: 20px;
}


.footer{
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 90;
  bottom: 0px;
}

.footer a{
  margin: 0px 10px;
}

.footer img {
  width: 30px;
}
.footer img:hover{
  margin-bottom: 2px;
}

.footer h3{
  font-size: 18px;
  color:white
}


/* mobile view */
@media screen and (max-width:899px){
  .nav{
    display: none;
  }
  .bannerTitle{
    font-size: 40px;
  }
  .banner img{
    object-fit: cover;
  }
  .bannerTitle{
    left:15px;
    top:18px;
    position: fixed;
  }
}
@media screen and (min-width:900px){
  .mobileNav{
    display: none;
  }
}


@media screen and (max-width:599px){
  .bannerButton{
    font-size: 20px;
  }
  .artworkImageContainer img{
    width: 98%;
  }
}


/* This positions the cross centrally in the button */
.bm-cross-button > span {
  top: 10px !important;
  right: 24px !important;
}

/* This targets the actual elements that make up the cross */
.bm-cross {
  width: 5px !important;
  height: 20px !important;
}

/* masonry grid  */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}




